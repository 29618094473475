.background {
  background-image: linear-gradient(
    45deg,
    hsl(193deg 65% 16%) 0%,
    hsl(190deg 59% 20%) 11%,
    hsl(186deg 53% 24%) 22%,
    hsl(183deg 46% 28%) 33%,
    hsl(178deg 39% 33%) 44%,
    hsl(172deg 34% 39%) 56%,
    hsl(165deg 29% 45%) 67%,
    hsl(157deg 26% 52%) 78%,
    hsl(147deg 29% 59%) 89%,
    hsl(133deg 32% 66%) 100%
  );

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: auto;

  scrollbar-gutter: stable;
}

.outerContainer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% - 18px);
  margin-left: 18px;
}

.innerContainer {
  padding: 50px 0;
  display: table-cell;
  vertical-align: middle;
}

.page {
  background-color: #ebebeb;
  margin-left: auto;
  margin-right: auto;
  width: 21cm;
  height: 29.7cm;
  border-radius: 3px;
  box-shadow: 0 0 0.6cm rgba(0, 0, 0, 0.6);
}

.pageContent {
  font-family: 'Hanken Grotesk', sans-serif;
  height: 100%;
}

div::-webkit-scrollbar {
  width: 18px;
}
 
div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 999px;
  background-clip: padding-box;
  border: 6px solid rgba(0, 0, 0, 0);
}
