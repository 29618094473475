h1 {
  margin: -20px 0 -2px 0;
  text-align: center;
  font-size: 50px;
  letter-spacing: 3px;
  font-weight: 400;
}

h2 {
  margin: 0 0 10px 0;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  letter-spacing: 1px;
}

h3 {
  text-align: center;
  margin: 14px 0 10px 0;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
}

p {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 300;
}

header {
  justify-content: center;
  grid-area: header;
  border-radius: 5px 5px 0 0;
  min-height: 100%;

}

hr {
  border: none;
  height: 2px;
  background-color: black;
  margin: 20px 0 0 0;
}
  
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr 1fr;

  grid-template-areas:
  "header header header"
  "side main main"
  "side main main";
  height: 100%;
  padding: 40px;
}

span.firstName {
  font-weight: 400;
}

section.secondaryInfo {
  border-right: 2px solid black;
  grid-area: side;
  height: calc(100% - 80px);
  padding: 0 15px;
}

section.secondarySubsection > hr {
  border: none;
  background: none;
  border-bottom: 2px dashed black;
  margin: auto;
  margin-top: 10px;
}

section.secondarySubsection > ul {
  margin: 0;
  font-size: 13px;
  font-weight: 300;
}

.date {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 300;
}

section.mainInfo {
  grid-area: main;
  height: calc(100% - 80px);
  padding: 0 15px;
}

section.mainSubsection > hr {
  border: none;
  background: none;
  border-bottom: 2px dashed black;
  margin: auto;
  margin-top: 10px;
}

section.mainSubsection > ul {
  margin: 0;
  font-size: 15px;
  font-weight: 300;
}

.phil {
  height: 90px;
  width: 430px;
  margin: 15px 0 0 7px;
  padding: 0;
}